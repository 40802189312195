import React from 'react'
import BgAnimations from './BgAnimations'

// background animation for back screen
const LeftAnimation = () => {

  return(
    <div className="bgAnims-container">
      <BgAnimations
        target = 'leftAnim'
      />
    </div>

  )
}
export default LeftAnimation