import React, { useEffect, useState } from 'react'
import './vrapChat.css'
import { useDispatch, useSelector } from 'react-redux'
import { vrapGetOne } from '../../../../../services/srvVraps'
import DayAndTime from '../../../../../../../functions/DayAndTime'
import { postChat } from '../../../../../reducers/rdc-vraps'
import TextArea from '../../../../../../../components/UI/TextArea/TextArea'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import Poll from '../../../../../../../utilities/Poll'

// { vrap }
const VrapChat = ({ id, poll }) => {

  const dispatch = useDispatch()
  const vraps = useSelector( state => state.vraps)
  const user = useSelector(state => state.user)
  const vrap = useSelector( state => state.vraps).filter(item => item.id === id)[0]
  const token = useSelector(state => state.user.token)


  const ChatList = () => {

    // ------------- Update chat list by polling------------------
    // state for updated data of session
    const[sessionData, setSessionData] = useState(vrap)

    // get latest session
    const updatedSession = async () => {
      let vrap = await vrapGetOne(token, id)
      setSessionData(vrap.data)
    }
    // (true/false, maxRounds, time in ms)
    const pollValue = Poll(poll, 100, 5000)

    useEffect(() => {
      updatedSession()
    }, [user, dispatch, pollValue])
    // ------------------------------------------------------------

    return(
      <>
        {sessionData.vrapSessionChats.map(item =>
          <div key={item.id} className = "vrap-chat-message-conteiner">
            <div className = "vrap-chat-message-header">
              { item.firstname + ' ' + item.lastname} <DayAndTime date = {item.date} />
            </div>
            <div className = "vrap-chat-message-body">
              {item.messageBody}
            </div>
          </div>
        )}
      </>
    )
  }
  const NewMessage = () => {
    const [neewMessage, setNewMessage] = useState('')

    // send message
    const sendHandeler = () => {
      const body = {
        messageBody: neewMessage,
        firstname: user.firstname,
        lastname: user.lastname,
        authorId: user.id
      }
      dispatch(postChat(user.token, body, vrap.id, vraps))
    }

    return(
      <div>
        <label>Join the conversation</label>
        <div className = "vrap-chat-write-area">
          <TextArea
            trickers ={[neewMessage]}
            className = "passed"
            placeholder='Write a message'
            value={neewMessage}
            onChange={(e) => {setNewMessage(e.target.value)}}/>
          <IconButton
            char = 'l'
            size="30px"
            onClick={sendHandeler}
            className="icon-button-main"
          />
        </div>
      </div>
    )
  }
  return(
    <div className = "vrap-component-container vrap-chat-padding">
      <NewMessage/>
      <div className = "vrap-chat-container flex-column-reverse">
        <ChatList/>
      </div>
    </div>
  )
}

export default VrapChat



