import React from 'react'


const SolidWhite = () => {

  return(
    <div className="solid-white">
    </div>

  )
}
export default SolidWhite