import React, { useEffect, useState } from 'react'
import './measuredata.css'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { postMeasureImage } from '../../../../../reducers/rdc-vraps'
import { vrapGetMeasureImage } from '../../../../../services/srvSessionRecord'
import Loader from '../../../../../../../components/UI/Loader/Loader'


const MeasureData = ({ sizeMeasures, session, setsizeMeasures, sessionId, activeRecordId, sessionType, sessions }) => {
  const [fileToUpload, setFileToUpload] = useState()
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(false)
  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  // submit pdf
  const sendPdf = async (e) => {
    e.preventDefault()
    setLoading(true)
    dispatch(postMeasureImage(token, sessionId, activeRecordId, fileToUpload, sessions))
  }


  // data of active record
  const activeRecord = session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0]
  // get signed URL for PDF
  console.log('measuresUrl:', activeRecord.measuresUrl)
  useEffect(()  => {
    if(activeRecord.measuresUrl !== 'notSet'){
      vrapGetMeasureImage(token, activeRecord.measuresUrl).then(response => {
        setFile(response.data)
        setLoading(false)
      })
        .catch(error => {
          console.log(error)
          setFile('img/noPdf.png')
        })
    } else {
      setFile('img/noPdf.png')
    }
  },[session, activeRecordId])

  const inputHandelr = (e) => {
    setFileToUpload(e.target.files[0])
  }

  // pdf embedded by object is freesing Safari.

  return(
    <div className="vrap-video-inner-box">
      { sessionType !== 'Monitoring' && !loading &&
        <>
          <label>Choose a PDF file to upload</label>
          <form onSubmit={sendPdf}>
            <input type="file" name="pdf" accept="application/pdf" onChange={inputHandelr}/>
            <button type="submit">Upload</button>
          </form>
        </>

      }
      {
        sessionType !== 'Monitoring' && loading &&
          <Loader title="Uploading PDF"/>
      }

      {activeRecord.measuresUrl !== 'notSet' && !loading ?
        <>
          <iframe border="0" cellSpacing="0" className="vrap-pdf" src={file} width = "100%" height="100%"/>
        </>
        :
        <div className="flex-center" style={{ height: '100%' }}>
          <p>QC report PDF not set yet</p>
        </div>
      }

      <div className={'vrap-session-backButton'}>
        <IconButton
          className = 'icon-button-secondary'
          char={sizeMeasures === 'vrap-measures-normal' ? '5' : '3'}
          text="shrink the box"
          onClick={ () => setsizeMeasures( sizeMeasures === 'vrap-measures-normal' ? 'vrap-measures-enlarged' : 'vrap-measures-normal')}
        />
      </div>

    </div>


  )
}

export default MeasureData



