import React, { useState } from 'react'
import './Loader.css'

const Loader = ({ title, text, errorTitle, errorMessage,  }) => {

  const[shoeLoader, setShowLoader] = useState(true)
  setTimeout( () => setShowLoader(false), 500000)

  const NoResponse = () => {
    return(
      <>
        { !errorTitle && <h3>Still in process</h3> }
        { !errorMessage && <p>Process is taking longer than expected...</p> }
        { errorTitle && <h3> {errorTitle} </h3> }
        { errorMessage && <p> {errorMessage} </p> }
      </>
    )
  }

  const Loading = () => {
    return(
      <div className="loader-title">
        <div className="loader-container">
          {title && <h3>{title}</h3>}
          {text && <p>{text}</p>}
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    )

  }
  return(
    <>
      { shoeLoader === true
        ? <Loading/>
        : <NoResponse/>
      }
    </>
  )
}

export default Loader