// Very poor polling solution to update state for chat
import { useEffect, useState } from 'react'

const Poll = (state, maxRounds, time) => {

  const [pollCount, setPollCount] = useState(0)
  const [count2, setCount2] = useState(1)

  useEffect(() => {
    const controller = new AbortController()
    setTimeout(() => {setPollCount(count2)},time)

    // abort
    if(pollCount > maxRounds || state === false) {return () => controller.abort()}
  },[count2])
  useEffect(() => {
    const controller = new AbortController()
    pollCount < maxRounds && state === true && setTimeout(() => {setCount2(pollCount+1)},time)

    // abort
    if(pollCount > maxRounds || state === false) {return () => controller.abort()}
  },[pollCount])

  return pollCount
}
export default Poll