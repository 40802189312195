import React from 'react'
import { useSelector } from 'react-redux'
// import IconButton from '../IconButton/IconButton'
import './Footer.css'


const Footer = () => {
  const theme = useSelector(state => state.theme)
  const d = new Date()
  let year = d.getFullYear()

  return(
    <div className = {'footer' + theme}>
      <div className="footer-branding"></div>
      <div className="rights">All rights reserver Gelecta Oy © {year}</div>
    </div>
  )
}

export default Footer