import React from 'react'


const SpinningHorizon = () => {

  return(
    <div className="spinning-horizon">
    </div>

  )
}
export default SpinningHorizon