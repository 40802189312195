import React, { useEffect, useState } from 'react'
import './bgAnimations.css'
import { useDispatch, useSelector } from 'react-redux'
import { setUnits } from '../VrapUnits/reducers/rdc-vrapUnits'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Poll from '../../../../utilities/Poll'
import SpinningHorizon from './conponents/SpinningHorizon'
import SolidWhite from './conponents/SolidWhite'
import DiagonalRectangles from './conponents/DiagonalRectangles'
import SwayingBands from './conponents/SwayingBands'
import HorizontalRectangels from './conponents/HorizontalRectangels'



const BgAnimations = ({ target }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let id = url.substring(idIndex + 1)

  // get Vrap units
  useEffect(() => {
    user && dispatch(setUnits( user.token ))
  },[user, dispatch])

  const units = useSelector(state => state.vrapUnits)
  const [poll, setPoll] = useState(true)

  // (true/false, maxRounds, time in ms)
  const maxRounds = 100
  const pollValue = Poll(poll, maxRounds, 4000)

  // get Vrap units (polled by pollCount)
  useEffect(() => {
    user && dispatch(setUnits( user.token ))
  },[user, dispatch, pollValue])

  const exitHandler = () => {
    // stop polling and abort Poll function
    setPoll(false)
    if(id.length !== 24) {history.push('/home')} else {history.push('/vraps')}
  }

  // find out actual unit animation setting if exists
  let currentUnit = 'unset'
  let currentAnimPower = 1
  let currentAnimation = 'spinning-horizon'

  // current unit, if device is detected
  if(units && units.filter(unit => unit.currentUnit === true).length === 1){
    currentUnit = units.filter(unit => unit.currentUnit === true)[0]
    currentAnimPower = currentUnit[target].power/100
    currentAnimation = currentUnit[target].source

    // if :id is specified in window.location, we are using it, (link from sessions route)
  }  if(id.length === 24){
    currentUnit = units.filter(unit => unit.id === id)[0]
    currentAnimPower = currentUnit[target].power/100
    currentAnimation = currentUnit[target].source
  }

  return(
    <div className="bgAnims-container" onClick = {exitHandler}>
      <div style={{ opacity: currentAnimPower, zIndex: 100 }}/>

      {/* Animations */}
      {currentAnimation === 'spinning-horizon' && <SpinningHorizon/>}
      {currentAnimation === 'solid-white' && <SolidWhite/>}
      {currentAnimation === 'horizontal-rectangles' && <HorizontalRectangels/>}
      {currentAnimation === 'diagonal-rectangles' && <DiagonalRectangles/>}
      {currentAnimation === 'swaying-bands' && <SwayingBands/>}
      {currentAnimation === 'swaying-bands' && <SwayingBands/>}

      <div className="bg-animation-info">
        Current Animation: {target + ' - ' + currentAnimation} - power: {currentAnimPower * 100 + '% - '}
        {maxRounds - pollValue > 0 ?
          <> server listening left: {maxRounds - pollValue} </>
          : <span className="blink"> Server listening time is end, please refresh page</span>}
          - Click screen to return in home page
        {id.length === 24 && <>{' - '}This animation is detected by session settings &lang; {currentUnit.unitName} &rang;</>}
      </div>
      {currentUnit === 'unset' &&
      <div className="bgAnim-noDevice">
        <h1> No vrap unit detected</h1>
        <p>showing default animation</p>
      </div>}
    </div>
  )
}
export default BgAnimations