import React from 'react'
// import UiCard from '../../../../components/UI/UiCard/UiCard'
import BodyComponents from './BodyButtons/BodyComponents'
import { useSelector } from 'react-redux'
import IconButton from '../../../components/UI/IconButton/IconButton'
import UiCard from '../../../components/UI/UiCard/UiCard'
import BodyButtons from './BodyButtons/BodyButtons'
// import { useSelector } from 'react-redux'


const Vrap = ({ setFilterdVraps, setSearchWord, searchWord, vraps, vrap, setVraps, selectedID, setSelectedID, bodyContainer, setBodyContainer }) => {
  const units = useSelector(state => state.user && state.vrapUnits)

  const backButtonHandler = () => {
    console.log('klikki')
    setSelectedID(null)
    setBodyContainer(null)
  }
  return(
    <>
      {/* Back button */}
      { selectedID === vrap.id &&
      <div className = "UIcard-exit-button"
        onClick={backButtonHandler}
      >
        <IconButton
          className="icon-button-secondary"
          char="x"
          size="20px"
        />
      </div>
      }
      {/* if no vrap is chosen or if vrap id is same show content */}
      { selectedID === null | selectedID === vrap.id ?
        <UiCard
          headerTitle = {vrap.sessionName}
          hederInfo = ''
          footerInfo = {vrap.description}
          footerInfoRight = {units.filter(u => u.id === vrap.unit)[0].unitName}
          onClick = {() => setBodyContainer('1')}
        >
          <BodyButtons
            vrap = {vrap}
            setBodyContainer = {setBodyContainer}
            bodyContainer = {bodyContainer}
            selectedID = {selectedID}
            setSelectedID = {setSelectedID}
          />

          <div>
            <BodyComponents
              bodyContainer = {bodyContainer}
              setSelectedID = {setSelectedID}
              selectedID  = {selectedID}
              setBodyContainer  = {setBodyContainer}
              setVraps = {setVraps}
              vraps = {vraps}
              vrap = {vrap}
              searchWord = {searchWord}
              setSearchWord = {setSearchWord}
              setFilterdVraps = {setFilterdVraps}
            />
          </div>

        </UiCard>
        :
        null
      }
    </>
  )
}
export default Vrap