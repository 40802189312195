import React from 'react'
import IconButton from '../../../../../components/UI/IconButton/IconButton'

const BodyButtons = ({ setBodyContainer, item, setSelectedID }) => {

  const scrollTop = () => {
    window.scrollTo({ top: 0,  behavior: 'smooth' })
  }

  return(
    <div className='body-buttons-container'>
      <IconButton
        char='L'
        className="icon-button-secondary"
        text = 'Edit'
        onClick={ () => { setBodyContainer('1'); scrollTop(); setSelectedID(item.id)}}
      />
    </div>

  )
}

export default BodyButtons