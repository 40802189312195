import React from 'react'
import BodyEdit from './BodyEdit'

const BodyComponents = ({ item, bodyContainer, setSelectedID, selectedID, setBodyContainer }) => {

  return(
    <>
      { item &&
      <div>
        {bodyContainer === '1' &&
        <BodyEdit item = {item} setSelectedID={setSelectedID} selectedID={selectedID} setBodyContainer={setBodyContainer}/>
        }
      </div>
      }
    </>
  )
}

export default BodyComponents