import React from 'react'
// import React, { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { initializeThreads } from '../MessageReducers/threadsReducer'

const SessionProduction = () => {
  // const token = useSelector(state => state.user && state.user.token)
  // const company = useSelector(state => state.user && state.user.company)


  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(initializeThreads(token, company))
  // }, [token, dispatch])

  return(

    <div className="vrap-container flex-center">
      Session UI for Production
    </div>

  )
}

export default SessionProduction



