import React, { useState } from 'react'
import Button from '../../../../../components/UI/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../../../components/UI/Notification/notificationReducer'
import MeasureData from './components/MeasureData/MeasureData'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import VrapChat from './components/VrapChat/VrapChat'
import Session3Camera from './components/Session3Camera'
// import VideoControls from './components/VideoControls/VideoControls'
import Session2Camera from './components/Session2Camera'
import SessionMainCamera from './components/SessionMainCamera'
import { postNewRecord } from '../../../reducers/rdc-vraps'
import dateToDayAndTime from '../../../../../functions/dateToDayAndTime'
import ErrorSpotting from './components/ErrorSpotting/ErrorSpotting'

const Session = () => {
  const history = useHistory()
  // const units = useSelector(state => state.user && state.vrapUnits)
  const sessions = useSelector(state => state.vraps)
  const token = useSelector(state => state.user && state.user.token)
  console.log('token-session: ', token)

  const [size3Video, setSize3Video] = useState('vrap-video3-normal')
  const [size2Video, setSize2Video] = useState('vrap-video2-normal')
  const [sizeMainVideo, setSizeMainVideo] = useState('vrap-videoMain-normal')
  const [sizeMeasures, setsizeMeasures] = useState('vrap-measures-normal')
  const [poll, setPoll] = useState(true)

  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let sessionId = url.substring(idIndex + 2)

  // filter current session by id
  const session = sessions.filter(session => session.id === sessionId)[0]

  // find out type of module
  // A = monitoring
  // B = production
  // is defined as first character before id number in window.location

  const sessionType = url.substring(idIndex + 1).charAt(0) === 'A' ? 'Monitoring' : 'Production'
  console.log('type: ', sessionType)

  // state for active record ID
  const[activeRecordId, setActiveRecordId] = useState(
    session && session.vrapSessionRecords.length > 0
      // if record(s) exists
      ? session.vrapSessionRecords[session.vrapSessionRecords.length-1].id
      : '' // else mark it empty string
  )

  console.log('activeRecord: ', activeRecordId)

  // find out current active Unit
  // const currentUnit = session ? units.filter(unit => unit.id === session.unit)[0] : null
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)


  const saveSessionHandler = () => {
    setPoll(false)
    dispatch(setNotification({
      notification: 'The session is now saved', // title
      type: 'notification'
    }
    ))
    history.push('/vraps')
  }

  // add new record event
  const newRecordHandler = () => {
    dispatch(postNewRecord(token, session.id, sessions))
  }

  const cancelHandler = () => {
    setPoll(false)
    history.push('/vraps')
  }
  return(

    <>
      <div className= {'vrap-container ' + theme}>

        {/* Header */}
        <div className="vrap-session-header">
          <h3>{session.sessionName}</h3>
          <div>Session type: {sessionType}</div>
          <div>
            <select value = {activeRecordId} onChange={(e) => setActiveRecordId(e.target.value)}>
              {
                session.vrapSessionRecords.map((record, index) => {

                  return(
                    <option
                      value = {record.id}
                      key={record.id}>
                      {index + 1 + ' • ' + dateToDayAndTime(record.date)}
                    </option>
                  )
                })
              }
            </select>
          </div>
          <Button type = "btn-alert" className="buttonGreen" onClick={newRecordHandler} label ="Add new record"/>
          <Button
            label = 'Leave session'
            type = 'alert'
            className="buttongreen"
            onClick={cancelHandler}
            icon = 'back-white'
          />
        </div>

        {/* Main Video */}
        <div className= { 'flex-center ' + sizeMainVideo}>
          { sessionType === 'Production' ?
            <SessionMainCamera
              sizeMainVideo= {sizeMainVideo}
              setSizeMainVideo = {setSizeMainVideo}
              id = {sessionId}
              sessionType = {sessionType}
            /> :
            <div>Monitoring Video coming here</div>
          }
        </div>

        {/* ERROR SPOTTING */}
        <div className = {'vrap-error-spotting ' + theme}>
          <ErrorSpotting
            sessionType = {sessionType}
            activeRecordId = {activeRecordId}
          />
        </div>

        {/* process video (video 2) */}

        <div className= { 'flex-center ' + size2Video}>
          <Session2Camera
            id = {sessionId}
            setSize2Video = {setSize2Video}
            size2Video = {size2Video}
            sessionType = {sessionType}
            activeRecordId = {activeRecordId}
            session = {session}
            sessions = {sessions}
          />
        </div>

        {/* parameter Image - VIDEO 3 */}

        <div className= { 'flex-center ' + size3Video}>
          <Session3Camera
            id = {sessionId}
            setSize3Video = {setSize3Video}
            size3Video = {size3Video}
            sessionType = {sessionType}
            activeRecordId = {activeRecordId}
            session = {session}
            sessions = {sessions}
          />
        </div>

        {/* Measure Data - vrap-bottom-measures*/}
        <div className = {sizeMeasures + ' ' + theme}>
          <MeasureData
            sizeMeasures = {sizeMeasures}
            setsizeMeasures = {setsizeMeasures}
            sessionId = {sessionId}
            sessionType = {sessionType}
            activeRecordId = {activeRecordId}
            session = {session}
            sessions = {sessions}
          />
        </div>

        {/* Chat */}
        <div className= {'vrap-chat ' + theme}>
          <VrapChat id = {session.id} token = {token} poll={poll} setPoll={setPoll} />
        </div>

      </div>

      <div className={'vrap-session-buttons-row ' + theme}>
        <Button
          label = 'Leave session'
          type = 'alert'
          className="buttongreen"
          onClick={cancelHandler}
          icon = 'back-white'
        />
        <Button
          label = 'End and save session'
          type = 'alert'
          onClick={saveSessionHandler}
          icon = 'bin'
          className="buttonGreen"
        />
      </div>

    </>

  )
}

export default Session



