import React from 'react'
import { useSelector } from 'react-redux'
import VideoButtons from './VideoControls/VideoButtons'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'

const SessionMainCamera = ({ id, setSizeMainVideo, sizeMainVideo }) => {
  const units = useSelector(state => state.user && state.vrapUnits)
  const sessions = useSelector(state => state.vraps)
  const token = useSelector(user => user.token)

  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let sessionId = url.substring(idIndex + 1)

  // if component is used as maincomponent, it uses id data of window.location
  // but if it is child component it uses value from props
  if(id !== undefined) { sessionId = id }

  const vrap = sessions.filter(session => session.id === sessionId)[0]

  // find out current active Unit
  const currentUnit = units.filter(unit => unit.id === vrap.unit)[0]

  // video sources
  const theSrc = currentUnit.mainVideoSource

  return(

    <>
      <div className="vrap-video-inner-box-MainVideo flex-center">
        <iframe src={theSrc} title="Production Video" className="vrap-video-preview">{theSrc}</iframe>
      </div>
      <div className={'vrap-session-mainCam-backButton'}>
        <IconButton
          className = { currentUnit.motorOption && currentUnit.motorOption === 'left' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
          char={sizeMainVideo === 'vrap-videoMain-normal' ? '4' : '3'}
          text="shrink the box"
          onClick={ () => setSizeMainVideo( sizeMainVideo === 'vrap-videoMain-normal' ? 'vrap-videoMain-enlarged' : 'vrap-videoMain-normal')}
        />
      </div>
      <VideoButtons
        currentUnit = {currentUnit}
        token = {token}
      />

    </>

  )
}

export default SessionMainCamera



