import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../components/Private/Home/Home'
import UserProfile from '../components/Private/UserProfile/UserProfile'
import Footer from '../components/UI/Footer/Footer'

// Account management
import { useSelector } from 'react-redux'
import NavBar from '../components/UI/NavBar/NavBar'
import Vraps from '../Apps/Vraps/Vraps'
import BgAnimation from '../Apps/Vraps/components/BgAnimations/BgAnimation'
import RightAnimation from '../Apps/Vraps/components/BgAnimations/RightAnimation'
import LeftAnimation from '../Apps/Vraps/components/BgAnimations/LeftAnimation'

const StaffRoutes = ({ activeModules }) => {
  const theme = useSelector(state => state.theme)
  return(
    <div className={'routes-container' + theme}>

      <Switch>
        <Route exact path="/home">             <NavBar activeModules = {activeModules}/>          <Home />             <Footer/> </Route>
        <Route exact path="/">                   <NavBar activeModules = {activeModules}/>        <Home />             <Footer/> </Route>
        <Route exact path="/profile">             <NavBar activeModules = {activeModules}/>       <UserProfile />      <Footer/> </Route>
        <Route exact path="/vraps">                      <NavBar />        <Vraps />             </Route>
        <Route exact path="/bgAnimation"> <BgAnimation/></Route>
        <Route exact path="/rightAnimation"> <RightAnimation/></Route>
        <Route exact path="/leftAnimation"> <LeftAnimation/></Route>
      </Switch>
    </div>
  )
}


export default StaffRoutes