const pollReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_POLL':
    return action.data

  default: return state
  }
}

export const setPoll = (count) => {
  return{
    type: 'SET_POLL',
    data: count
  }
}

export default pollReducer