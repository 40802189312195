import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextArea from '../../../../components/UI/TextArea/TextArea'
import Button from '../../../../components/UI/Button/Button'
import { addNewVrap } from '../../reducers/rdc-vraps'
import { setNotification } from '../../../../components/UI/Notification/notificationReducer'

const NewSession = ({ setBodyContainer }) => {
  const dispatch = useDispatch()
  const [sessionName, setSessionName ] = useState('')
  const [description, setDescription ] = useState('')
  const [monitoringAddress, setmoMitoringAddress ] = useState('')
  const [addressProduction, setAddressProduction ] = useState('')
  const [unit, setUnit ] = useState('')
  const user = useSelector(state => state.user)
  const units = useSelector(state => state.user && state.vrapUnits)
  const vraps = useSelector(state => state.vraps)


  const handlerStartSession = () => {
    // body to save as new session
    const body = {
      sessionName: sessionName,
      description: description,
      monitoringAddress: monitoringAddress,
      addressProduction: addressProduction,
      unit: unit
    }
    if(sessionName !== '' && description !== '' && monitoringAddress !== '' && addressProduction !== '' && unit !== ''){
    // dispatch new session
      const newSession = addNewVrap(user.token, body, vraps)
      dispatch(newSession)
      setBodyContainer(null)

      // this needed to fix later. Update state, not reload page
      setTimeout(() => {
        window.location.reload()
      },500)
    } else {
      dispatch(setNotification({ notification: 'Please fill in all fields in the form to continue' }))
    }}

  const handlerCancel = () => {
    setBodyContainer(null)
  }

  return(

    <div className = "UI-card-body-container">
      <p>
           Please fill up needed.... and then start new session.
      </p>
      {/* session name */}
      <div className = "input-group">
        <label>Name for session</label>
        <input
          className={ sessionName !== '' ? 'passed' : 'not-passed'}
          placeholder="Name for session *"
          value = {sessionName}
          onChange={(e) => {setSessionName(e.target.value)}}
        />
      </div>

      {/* session description */}
      <div className = "input-group">
        <label>Description</label>
        <TextArea
          trickers ={[description]}
          className = "passed"
          placeholder='Description'
          value={description}
          onChange={(e) => {setDescription(e.target.value)}}/>
      </div>

      {/* address of approver */}
      <div className = "input-group">
        <label>Address of approver</label>
        <TextArea
          trickers ={[monitoringAddress]}
          className = "passed"
          placeholder='Address of approver'
          value={monitoringAddress}
          onChange={(e) => {setmoMitoringAddress(e.target.value)}}/>
      </div>

      {/* address of approver */}
      <div className = "input-group">
        <label>Address of approver</label>
        <TextArea
          trickers ={[addressProduction]}
          className = "passed"
          placeholder='Address of production'
          value={addressProduction}
          onChange={(e) => {setAddressProduction(e.target.value)}}/>
      </div>

      {/* address of approver */}
      <div className = "input-group">
        <label>Select approval unit</label>

        <select onChange={(e) => {setUnit(e.target.value)}}>
          <option value="">Please select approval unit</option>
          { units.map( unit => {
            return(
              <option value= {unit.id} key ={unit.id}>{units.filter(u => u.id === unit.id)[0].unitName}</option>
            )
          })

          }
        </select>
      </div>


      <div className="flex-row-container mp-mt-40 mp-mb-20" >
        <Button
          className = "buttonGreen"
          label = 'Start session'
          type = 'buttonGreen'
          icon = 'update'
          onClick={handlerStartSession}
        />

        <div onClick={handlerCancel}>
          <Button
            type = "buttonNoEnter"
            label='Cancel'
            icon='undo'
          />
        </div>

      </div>
    </div>

  )
}

export default NewSession



