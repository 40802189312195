import React from 'react'
import { useSelector } from 'react-redux'
import Body_BasicData from '../UIcardBodyContainers/Body_BasicData/Body_BasicData'
import Session from '../UIcardBodyContainers/Session/Session'
import SessionProduction from '../UIcardBodyContainers/SessionProduction/SessionProduction'

// import filters from '../../AppHeader/filters'

const BodyComponents = ({ vrap, vraps, setFilterdVraps, bodyContainer, selectedID, setSelectedID, setBodyContainer }) => {

  const user = useSelector(state => state.user)

  return(
    <>
      <div>
        {bodyContainer === '1' &&
        <Body_BasicData setBodyContainer = {setBodyContainer} selectedID = {selectedID} vrap = {vrap} setFilterdVraps = {setFilterdVraps} vraps = {vraps} token = {user.token} setSelectedID={setSelectedID}/>}
        {bodyContainer === '2' &&
        <Session setBodyContainer = {setBodyContainer} selectedID = {selectedID} vrap = {vrap} setFilterdVraps = {setFilterdVraps} vraps = {vraps} token = {user.token} setSelectedID={setSelectedID}/>}
        {bodyContainer === '3' &&
        <SessionProduction setBodyContainer = {setBodyContainer} selectedID = {selectedID} vrap = {vrap} setFilterdVraps = {setFilterdVraps} vraps = {vraps} token = {user.token} setSelectedID={setSelectedID}/>}
      </div>
    </>
  )
}
export default BodyComponents
