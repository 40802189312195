/* eslint-disable no-unexpected-multiline */
const filters = (customers, searchWord) => {

  let showCards
  if( customers && Array.isArray(customers) === true ) {
  // ------------ FILTERS --------------------------------------
    showCards = customers.filter
    (
      x => (x.sessiontName && x.sessiontName.toString().includes(searchWord)) // by session name
        || (x.description && x.description.toLowerCase().includes(searchWord.toLowerCase()))  // by lastName
        || (x.unit && x.unit.toLowerCase().includes(searchWord.toLowerCase())) // by unit name
    )
  }
  return showCards
}

export default filters