import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateVrap } from        '../../../reducers/rdc-vraps'
import { deleteVrap }  from       '../../../reducers/rdc-vraps'
import Button from                '../../../../../components/UI/Button/Button'
import HelpText from              '../../../../../components/UI/HelpText/HelpText'
import TextArea from              '../../../../../components/UI/TextArea/TextArea'
import IconButton from            '../../../../../components/UI/IconButton/IconButton'
import { setNotification } from   '../../../../../components/UI/Notification/notificationReducer'

const Body_BasicData = ({ vrap, setBodyContainer, setFilterdVraps, setSelectedID }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const help = useSelector(state => state.help.show)
  const units = useSelector(state => state.user && state.vrapUnits)
  const vraps = useSelector(state => state.vraps)

  // function to replace new value for named key in object
  function modifyKey(objectToModify, keyToModify, newValue){
    objectToModify[keyToModify] = newValue
    return objectToModify
  }
  // ------------- input with update handler -------------------------------
  const DataInput = ({ title, data, name }) => {
    const handlerUpdateClient = () => {
      // close input
      setOpenform(false)

      // New object to update
      const modifiedItem = modifyKey(vrap, name, newData)

      // updating vrap to db
      modifiedItem && dispatch(updateVrap( user.token, modifiedItem, vrap.id, vraps ))

      // mapping updating vrap to vraps array
      const modifiedItems = vraps.map(x => {
        if (x.id === vrap.id){
          return modifyKey(x, name, newData)
        } else return x
      })

      // updating vrap state
      setFilterdVraps(modifiedItems)
    }

    // states
    const [openForm, setOpenform] = useState(false)
    const [newData, setNewData]= useState(data)

    return(
      <div className="card-list even-odd">
        <div className="flex-container-info hover-pointer" onClick={() => { setOpenform(false)}}>{title}</div>
        { openForm === false ?
          <>
            <div className="flex-item-right hover-pointer" onClick={() => { setOpenform(true)}}>{data}</div>
            {name && help === true &&
            <div className="flex-item-right align-items-end">
              <IconButton
                onClick={() => { setOpenform(true)}}
                className="icon-button-secondary"
                char="L"
                text="Edit"
                size="30px"
              />
            </div>}
          </>
          :
          <>
            <div className="flex-item-right">
              <TextArea
                onChange={(e) => {setNewData(e.target.value)}}
                className = "passed"
                value = {newData}
                trickers ={newData}
              />

            </div>
            {name &&
            <>
              <div className="flex-item-right align-items-end">
                <IconButton
                  onClick={handlerUpdateClient}
                  className="icon-button-main"
                  char="u"
                  text="OK"
                  size="30px"
                />
              </div>
              { help === true &&
              <div className="flex-item-right align-items-end">
                <IconButton
                  onClick={() => { setOpenform(false)}}
                  className="icon-button-gray"
                  char="X"
                  text="Cancel"
                  size="30px"
                />
              </div>}
            </>
            }
          </>
        }
      </div>
    )
  }
  const DataSelect = ({ title, data, name }) => {
    const handlerUpdateClient = () => {
      // close input
      setOpenform(false)

      // New object to update
      const modifiedItem = modifyKey(vrap, name, newData)

      // updating vrap to db
      modifiedItem && dispatch(updateVrap( user.token, modifiedItem, vrap.id, vraps))

      // mapping updating vrap to vraps array
      const modifiedItems = vraps.map(x => {
        if (x.id === vrap.id){
          return modifyKey(x, name, newData)
        } else return x
      })

      // updating vrap state
      setFilterdVraps(modifiedItems)
    }

    // states
    const [openForm, setOpenform] = useState(false)
    const [newData, setNewData]= useState(data)

    return(
      <div className="card-list even-odd">
        <div className="flex-container-info hover-pointer" onClick={() => { setOpenform(false)}}>{title}</div>
        { openForm === false ?
          <>
            <div className="flex-item-right hover-pointer" onClick={() => { setOpenform(true)}}>{units && units.length>0 && units.filter(u => u.id === data)[0].unitName}</div>
            {name && help === true &&
            <div className="flex-item-right align-items-end">
              <IconButton
                onClick={() => { setOpenform(true)}}
                className="icon-button-secondary"
                char="L"
                text="Edit"
                size="30px"
              />
            </div>}
          </>
          :
          <>
            <div className="flex-item-right">
              <select onChange={(e) => {setNewData(e.target.value)}}>
                <option value = {data}>Choose approval Unit</option>
                { units.map( unit => {
                  return(
                    <option value= {unit.id} key ={unit.id}>{units.filter(u => u.id === unit.id)[0].unitName}</option>
                  )
                })

                }
              </select>
            </div>
            {name &&
            <>
              <div className="flex-item-right align-items-end">
                <IconButton
                  onClick={handlerUpdateClient}
                  className="icon-button-main"
                  char="u"
                  text="OK"
                  size="30px"
                />
              </div>
              { help === true &&
              <div className="flex-item-right align-items-end">
                <IconButton
                  onClick={() => { setOpenform(false)}}
                  className="icon-button-gray"
                  char="X"
                  text="Cancel"
                  size="30px"
                />
              </div>}
            </>
            }
          </>
        }
      </div>
    )
  }

  const handleDeleteCustomer = () => {
    dispatch(setNotification({
      notification: 'The session will be deleted, this cannot be undone', // title
      type: 'modal',
      onClickConfirm: () => {
        setBodyContainer(null)
        setSelectedID(null)
        dispatch(deleteVrap(user.token, vrap.id))
        setTimeout(() => {
          dispatch(setNotification({ notification: 'The session is now deleted' }))
          setFilterdVraps(vraps.filter(cust => cust.id !== vrap.id))
        },500)
      }
    }
    ))

  }
  // ----------------- ToggInput ----------------------------------//


  return(
    <>
      <h4 className="body-header">Basic event information</h4>
      <HelpText
        text={
          <>
            <p>Help text here</p>
          </>
        }
      />
      { vrap && vrap !== [] &&
        <div>
          <div style={{ marginBottom: '30px' }}>

            <DataInput title = "Name of session"   data = {vrap.sessionName}        name = "sessionName"/>
            <DataInput title = "Description" data = {vrap.description}              name = "description"/>
            <DataInput title = "Address of production factory" data = {vrap.addressProduction}  name = "addressProduction"/>
            <DataInput title = "Address of approval place" data = {vrap.monitoringAddress}  name = "monitoringAddress"/>
            <DataSelect title = "Unit" data = {vrap.unit}  name = "unit"/>
            <div className='buttons-row mp-mt-20'>
              <Button
                label = 'Delete session'
                className="buttonRed"
                onClick={handleDeleteCustomer}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default Body_BasicData
