import React from 'react'


const DiagonalRectangles = () => {

  return(
    <div className="diagonal-rectangles">
    </div>

  )
}
export default DiagonalRectangles