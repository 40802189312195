import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppHeader from './AppHeader'
import Item from './BodyComponents/Item'
import { setUnits } from './reducers/rdc-vrapUnits'



const VrapUnits = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const items = useSelector(state => state.users && state.vrapUnits)
  const [selectedID,     setSelectedID]            = useState(null)
  const [bodyContainer,  setBodyContainer]         = useState(null)
  const [filteredItems,   setFilterdItems]           = useState(items)

  // get Vrap units
  useEffect(() => {
    user && dispatch(setUnits( user.token ))
  },[user, dispatch, bodyContainer])

  // restore filters if new tag is created or changed
  useEffect(() => {
    setFilterdItems(items)
  },[items])

  const [searchWord, setSearchWord] = useState('')
  return(
    <div className="container">
      <AppHeader
        selectedID={selectedID}
        filteredItems = {filteredItems}
        setFilterdItems = {setFilterdItems}
        bodyContainer = {bodyContainer}
        setBodyContainer = {setBodyContainer}
        searchWord = {searchWord}
        setSearchWord = {setSearchWord}
      />
      <div className = "flex-column-reverse mp-mt-20">
        {filteredItems && filteredItems.slice(0,50).map(item => {
          return(
            <div key={item.id}>
              <Item
                key={item.key}
                item={item}
                selectedID = {selectedID}
                setSelectedID = {setSelectedID}
                bodyContainer = {bodyContainer}
                setBodyContainer = {setBodyContainer}
                searchWord = {searchWord}
                setSearchWord = {setSearchWord}

              />
            </div>
          )
        })}
      </div>

    </div>

  )
}

export default VrapUnits