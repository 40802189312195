import React from 'react'
import {  useSelector } from 'react-redux'
import Title from '../../UI/Title/Title'
import { useHistory } from 'react-router-dom'
import langs from '../../../langs'
import Button from '../../UI/Button/Button'
import UiCard from '../../UI/UiCard/UiCard'


const Accounts = () => {
  // const dispatch = useDispatch()
  const history = useHistory()
  const companies = useSelector(state => state.companies)
  const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...

  const trans = langs.lang // translation

  const editCompanyHandler = (id) => {
    history.push(`/accounts/editAccount/${id}`)
  }

  const AccountItem = ({ company }) => {
    const companyId = company.id
    const users = useSelector(state => state.users.allUsers)
    const accountUsers = users.filter(u => u.company === companyId)

    const Names = () => {
      const toUserEditHandler = (userid) => {
        history.push(`/admin/user/${userid}`)
      }
      return(
        <>
          {accountUsers.map(u =>
            <div
              key = {u.id} onClick = {() => toUserEditHandler(u.id)}>
              {u.firstname} {u.lastname}
            </div>
          )}
        </>
      )
    }
    return(
      <UiCard
        headerTitle={company.companyName}
      >
        <div className="giftcard-body-container">
          <div className="user-list-bottom-row">
            <div className="user-list-item-name">
              <Names/>
            </div>
            <div>
              {company.active === true ? 'active' : 'sleeping'}
            </div>
            <Button
              id={`edit-user-button-${company.companyName}`}
              type="buttonGreen"
              onClick={() => editCompanyHandler(company.id)}
              label={trans.edit[lN]}
              icon ="edit"
            />
          </div>
        </div>
      </UiCard>
    )
  }

  const CompanyList = () => {
    return(
      companies.map(company =>
        <div key={company.id}>
          <AccountItem
            company={company}
          />
        </div>
      )
    )
  }

  return(
    <div>
      <Title text= "Tilien hallinta" icon="w"/>
      {companies && <CompanyList/>}
    </div>
  )
}

export default Accounts