const dateToDayAndTime = (date) => {
  if(date)
    return new Date(date).getDate() +
    '.' + (new Date(date).getMonth() + 1) +
    '.' + new Date(date).getFullYear()
    + ' • ' + date.slice(11,13) +
    ':' + date.slice(14,16)
  else return  'error in time'
}

export default dateToDayAndTime