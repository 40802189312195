import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTheme } from '../../../../reducers/themeReducer'
import IconButton from '../../IconButton/IconButton'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
// import langs from '../../../../langs'
// import { useSelector } from 'react-redux'

const UserMenu = ({ toggleSettingsMenu }) => {
  // const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  // const trans = langs.lang // translation
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)


  const links =
    <>


      <h4><span className = "title-symbol">C</span>
        <span style={{ marginLeft: '10px' }}>Units</span>
      </h4>
      <Link to="/units">
        <div
          className="hover-pointer menu-link"
          onClick={toggleSettingsMenu}
          id="user-admin-button"
          data-testid="user-admin-button">
          Add | edit | remove Vrap Units
        </div>
      </Link>

      <div className = 'nav-close-button'>
        <IconButton
          char = 'x'
          className='icon-button-secondary'
          size = '20px'
          onClick={toggleSettingsMenu}
        />
      </div>
      <div className="hover-pointer">
        {theme === '' ?
          <h4 onClick={ () => dispatch(setTheme('-dark') )}>
            <span className = "title-symbol">A </span>Vaihda teemaa
          </h4>:
          <h4 onClick={ () => dispatch(setTheme('') )}>
            <span className = "title-symbol">A </span> Vaihda teemaa
          </h4>
        }
      </div>
    </>

  return(
    <div>
      <div id="user-menu" className={'menu' + theme}>

        {links}

      </div>
    </div>
  )
}

export default UserMenu