


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { vrapGetProcessVideo } from '../../../../services/srvSessionRecord'
import { postProcessVideo } from '../../../../reducers/rdc-vraps'
import Loader from '../../../../../../components/UI/Loader/Loader'

const Session3Camera = ({ id, sessionType, session, activeRecordId }) => {
  const sessions = useSelector(state => state.vraps)
  const token = useSelector(state => state.user.token)

  const dispatch = useDispatch()

  const [fileToUpload, setFileToUpload] = useState()
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(false)

  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let sessionId = url.substring(idIndex + 1)

  // if component is used as maincomponent, it uses id data of window.location
  // but if it is child component it uses value from props
  if(id !== undefined) { sessionId = id }


  const sendVideo = (e) => {
    e.preventDefault()
    setLoading(true)
    dispatch(postProcessVideo(token, sessionId, activeRecordId, fileToUpload, sessions))
  }
  const inputHandelr = (e) => {
    setFileToUpload(e.target.files[0])
  }

  // data of active record
  const activeRecord = session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0]

  // get signed URL for PDF
  useEffect(()  => {
    if(activeRecord.processVideoUrl !== 'notSet'){
      vrapGetProcessVideo(token, activeRecord.processVideoUrl).then(response => {
        setFile(response.data)
        setLoading(false)
      })
        .catch(error => {
          console.log(error)
          setFile('img/noPdf.png')
        })
    } else {
      setFile('img/noPdf.png')
    }
  },[session, activeRecordId])

  return(

    <>
      <div className="vrap-video-inner-box">
        { sessionType !== 'Monitoring' && !loading &&
          <>
            <label>Choose a video file to upload</label>
            <form onSubmit={sendVideo}>
              <input type="file" name="pdf" accept="video/*" onChange={inputHandelr}/>
              <button type="submit">Upload</button>
            </form>
            { activeRecord.processVideoUrl !== 'notSet' &&

            <video className="vrap-video-preview" controls src={file} preload="auto"></video>



            }
          </>
        }
        {
          sessionType !== 'Monitoring' && loading &&
          <Loader title = "Uploading video" text="This may take up to a few minutes"/>
        }
        {
          sessionType === 'Monitoring' && !loading && activeRecord.processVideoUrl !== 'notSet' &&
          <video className="vrap-video-preview" controls src={file} preload="auto"></video>
        }
        {
          sessionType === 'Monitoring' && loading &&
          <Loader title = "Dowloading video"/>
        }
        {
          !loading && activeRecord.processVideoUrl === 'notSet' &&
          <div className="flex-center" style={{ height: '100%' }}>
            <p>Process video not set yet</p>
          </div>



        }



      </div>

    </>
  )
}

export default Session3Camera






