import React from 'react'
import dayjs from 'dayjs'
import LinkInPost from './LinkInPost/LinkInPost'
import { addQuotes } from '../../../MessageReducers/newPostReducer'
import { useDispatch } from 'react-redux'
import './Post.css'
import Quotes from './Quote/Quotes'
import authorColors from '../../../../../components/UI/AuthorCircle/authorColors'
import IconButton from '../../../../../components/UI/IconButton/IconButton'

const Post = ({ post, focusInput, setShowPostManager }) => {

  const dispatch = useDispatch()

  const images = (post.images && post.images.length > 0) && post.images.map(img =>
    <div className="image-in-post" key={Math.random() * 9999999 }>

      <object data={img} type="application/pdf" width="100%" height="500px">
      </object>
    </div>
  )

  const links = (post.links && post.links.length > 0) && post.links.map(url =>
    <LinkInPost
      url={url}
      key={Math.random() * 9999999}
    />
  )

  const onClickReply = (quotedPost) => {
    if(quotedPost){
      console.log('quotedPost: ', quotedPost)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setShowPostManager(true)
      dispatch(addQuotes({
        post: quotedPost,
        state: true
      }))
      focusInput()
    }
  }

  const createMarkup = (text) => {
    return { __html: text.replace(/\n/g, '<br />') }
  }

  const postBody = <div dangerouslySetInnerHTML={createMarkup(post.body)} />
  const authCol = post.author.slice(0,3)
  const postAuthor = <div><span className = "post-author-dot" style={{ color: 'rgb(' + authorColors([authCol,'yes']) + ')' }}>• </span>
    <span className="post-author">{post.firstname} {post.lastname}</span></div>
  const dateAndTime = <span style={{ color: 'var(--color-secondary)' }}>{dayjs(post.date).format('HH:mm • DD.MM.YYYY')}</span>

  return(
    <div className='post'>
      <div className='post-header'>
        {postAuthor}
        {dateAndTime}
      </div>
      {(post.quotes && post.quotes.length > 0) && <Quotes quotes={post.quotes}/>}
      {links && links}
      {postBody}
      {images}
      <div className="post-buttons-container">
        <div className="reply-link" >
          <IconButton
            char = "a"
            onClick={() => onClickReply(post)}
            className = 'icon-button-secondary msg-post-reply-button'
            size="15px"
          />
          <span style={{ marginLeft: '0.5em' }}>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Post