import  {
  vrapDelete,
  vrapNew,
  vrapUpdate,
  vrapsGet,
  paramPropAdd,
  chatPost
} from '../services/srvVraps'
import {
  vrapRecordNew,
  vrapPutMeasureImage,
  vrapPutProcessVideo,
  vrapPutParameterImage
} from '../services/srvSessionRecord'

const vrapsReducer = (state = null, action) => {
  switch (action.type) {
  case 'SET_VRAPS':
    return action.data
  default: return state
  }
}

// get vraps / sessions
export const getSessions = (token, company) => {
  return async dispatch => {
    let vraps = await vrapsGet(token, company)
    if(vraps.data === null){vraps = { data: 'noAccountYet' }}
    dispatch({
      type: 'SET_VRAPS',
      data: vraps.data
    })
  }
}

// ------- moved and updated from oneVrap reducer below --------


export const updateVrap = (token, body, itemId, items) => {
  return async dispatch => {
    const updatedSession = await vrapUpdate(token, body, itemId)
    const updatedSessions = items.map(unit => {
      if(unit.id === itemId) {
        return updatedSession.data
      }
      else return unit
    })
    dispatch({
      type: 'SET_VRAPS',
      data: updatedSessions
    })
  }
}

// post chat
export const postChat = (token, body, vrapId, items) => {
  return async dispatch => {
    console.log('body-param: ', body)
    const updatedVrap = await chatPost(token, body, vrapId)
    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId) {return updatedVrap.data }
      else return arrItem
    }
    )
    console.log('vraps-rdc: ', vraps )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// new record
export const postNewRecord = (token, vrapId, items) => {
  return async dispatch => {
    const updatedVrap = await vrapRecordNew(token, vrapId)
    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId) {return updatedVrap.data }
      else return arrItem
    }
    )
    console.log('vraps-rdc: ', vraps )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// new video
// todo

// new measureImage
export const postMeasureImage = (token, sessionId, recordId, file, items) => {
  return async dispatch => {
    const updatedVrap = await vrapPutMeasureImage(token, sessionId, recordId, file)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId) {
        return updatedVrap.data }
      else return arrItem
    }
    )
    console.log('vraps-rdc: ', vraps )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// post process video
export const postProcessVideo = (token, sessionId, recordId, file, items) => {
  return async dispatch => {
    const updatedVrap = await vrapPutProcessVideo(token, sessionId, recordId, file)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId) {
        return updatedVrap.data }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// post parameter image
export const postParameterImage = (token, sessionId, recordId, file, items) => {
  return async dispatch => {
    const updatedVrap = await vrapPutParameterImage(token, sessionId, recordId, file)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId) {
        return updatedVrap.data }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// new productionVideo
// todo

// post paramProp
export const addParamProp = (token, body, vrapId, items) => {
  return async dispatch => {
    console.log('body-param: ', body)
    const updatedVrap = await paramPropAdd(token, body, vrapId)
    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId) {return updatedVrap.data }
      else return arrItem
    }
    )
    console.log('vraps-rdc: ', vraps )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// new vrap
export const addNewVrap = (token, body, items) => {
  return async dispatch => {
    const vrap = await vrapNew(token, body)
    // push new vrap to array of vraps / sessions
    console.log('ctrl-items:', items)
    console.log('vrap.data', vrap.data)
    // const vraps = items.push(vrap.data)
    const vraps = items.concat([vrap.data])
    console.log('vrap.vraps', vraps)
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })
  }
}


// delete vrap
export const deleteVrap = (token, id) => {
  return async dispatch => {
    let item = await vrapDelete(token, id)

    dispatch({
      type: 'SET_VRAPS',
      data: item.data
    })
  }
}

// delete unit
export const deleteUnit = (token, id, items) => {
  return async dispatch => {
    let item = await vrapDelete(token, id, items)

    // console logging response to know server is doing the job
    console.log('deleted unit: ', item)

    // we dont need response from server to know the result:
    // lets filter unit away form array of units
    const resp = items.filter(arrItem => arrItem.id !== id)
    dispatch({
      type: 'SET_VRAPS',
      data: resp
    })
  }
}

export default vrapsReducer


