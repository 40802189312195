import React from 'react'
import BgAnimations from './BgAnimations'

// background animation for back screen
const RightAnimation = () => {

  return(
    <div className="bgAnims-container">
      <BgAnimations
        target = 'rightAnim'
      />
    </div>

  )
}
export default RightAnimation