import axios from 'axios'
import { configurate } from '../../../services/configurate'
const baseUrl = '/private/api/vrapsessionrecord'

export const vrapRecordNew = async (token, id) => {

  try{
    const config = configurate(token)
    const body = {
      parametersUrl: '',
      measuresUrl: '',
      processVideoUrl: ''
    }
    let response = await axios.post(baseUrl + '/' + id, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapRecordNew: ', err)
  }
}
// put measure image to record
// router.put('/measureImage/:sessionId/:recordId', async (req, res) => {
export const vrapPutMeasureImage = async (token, sessionId, recordId, file) => {

  // const conf = { headers: { 'Content-Type': 'multipart/form-data' } }

  try{
    const conf = { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }

    // Sending file as FormData
    const formData = new FormData()
    formData.append('pdf', file)

    let response = await axios.post(baseUrl + '/measureImage/' + sessionId + '/' + recordId + '/', formData, conf)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapPutMeasureImage: ', err)
  }
}

export const vrapGetMeasureImage = async (token, imageName) => {
  const config = configurate(token)
  try{
    let response = await axios.get(baseUrl + '/measureImage/' + imageName, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapGetMeasureImage: ', err)
  }
}

// vrapPutProcessVideo

export const vrapPutProcessVideo = async (token, sessionId, recordId, file) => {

  // const conf = { headers: { 'Content-Type': 'multipart/form-data' } }

  try{
    const conf = { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'video/*' } }

    // Sending file as FormData
    const formData = new FormData()
    formData.append('video', file)

    let response = await axios.post(baseUrl + '/processVideo/' + sessionId + '/' + recordId + '/', formData, conf)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/processVideo: ', err)
  }
}

export const vrapGetProcessVideo = async (token, imageName) => {
  const config = configurate(token)
  try{
    let response = await axios.get(baseUrl + '/processVideo/' + imageName, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapGetProcessVideo: ', err)
  }
}


// POST PARAMETER IMAGE

export const vrapPutParameterImage = async (token, sessionId, recordId, file) => {

  try{
    const conf = { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'image/*' } }

    // Sending file as FormData
    const formData = new FormData()
    formData.append('image', file)

    let response = await axios.post(baseUrl + '/parameterImage/' + sessionId + '/' + recordId + '/', formData, conf)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/processVideo: ', err)
  }
}

// GET PARAMETER IMAGE
export const vrapGetParameterImage = async (token, imageName) => {
  const config = configurate(token)
  try{
    let response = await axios.get(baseUrl + '/parameterImage/' + imageName, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapGetParameterImage: ', err)
  }
}





