import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { setModal } from '../../../UI/Modal/modalReducer'
import langs from '../../../../langs'

import UserForm from '../UserForm/UserForm'
import Button from '../../../UI/Button/Button'

const UpdateUser = (userToDisplay) => {
  const dispatch = useDispatch()
  const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const users = useSelector(state => state.users.allUsers)
  const trans = langs.lang // translation
  const history = useHistory()
  const userId = useRouteMatch('/admin/user/:id').params.id
  const user = users.filter( user => user.id === userId)[0]

  const delUser = (user) => {
    dispatch(setModal({
      // action name, what modal will execute
      action: 'delUser',
      id: user.id,
      // message for modal
      message: trans.sureToRemoveUser[lN] + user.username + '?',
      // message after modal is confirmed, is type is set to default
      notificationMessage: trans.user[lN] + ' ' + user.username + ' ' + trans.isRemoved[lN],
      // style for modal: alert / default.
      type: 'alert'
    }))
    // await deleteUser(token, user.id)
    history.push('/admin/users')
  }

  const backHandle = () => {
    history.push('/admin/users')
  }

  return(

    <div className="container">

      {/* back button */}
      <Button
        label = 'Back'
        type = 'buttonBack'
        onClick={backHandle}
        icon = 'back'
      />

      {/* Title */}
      <h2>
        { user && user.firstname }
        { user && user.lastname }
        <span style={{
          color: 'var(--main-color)',
          fontWeight: 'normal', marginLeft: '20px' }}>
          { user && user.username }</span>
      </h2>

      {/* User form */}
      { user &&
          <>
            <UserForm
              type='updateUser'
              user={user && user}
              delUser={delUser}
              userToDisplay={userToDisplay} />
          </> }
    </div>
  )
}

export default UpdateUser