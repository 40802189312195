import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import { vrapGetParameterImage } from '../../../../services/srvSessionRecord'
import Loader from '../../../../../../components/UI/Loader/Loader'
import { postParameterImage } from '../../../../reducers/rdc-vraps'

const Session3Camera = ({ id, setSize3Video, size3Video, activeRecordId, session, sessionType }) => {
  const units = useSelector(state => state.user && state.vrapUnits)
  const sessions = useSelector(state => state.vraps)
  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [fileToUpload, setFileToUpload] = useState()
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(false)

  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let sessionId = url.substring(idIndex + 1)

  // if component is used as maincomponent, it uses id data of window.location
  // but if it is child component it uses value from props
  if(id !== undefined) { sessionId = id }

  const vrap = sessions.filter(session => session.id === sessionId)[0]

  // send image to S3
  const sendImage = (e) => {
    e.preventDefault()
    setLoading(true)
    dispatch(postParameterImage(token, sessionId, activeRecordId, fileToUpload, sessions))
  }
  const inputHandelr = (e) => {
    setFileToUpload(e.target.files[0])
  }

  // data of active record
  const activeRecord = session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0]

  // get signed URL for PDF
  useEffect(()  => {
    if(activeRecord.parametersUrl !== 'notSet'){
      vrapGetParameterImage(token, activeRecord.parametersUrl).then(response => {
        setFile(response.data)
        setLoading(false)
      })
        .catch(error => {
          console.log(error)
          setFile('img/noPdf.png')
        })
    } else {
      setFile('img/noPdf.png')
    }
  },[session, activeRecordId])
  // find out current active Unit
  const currentUnit = units.filter(unit => unit.id === vrap.unit)[0]


  return(

    <>
      <div className="vrap-video-inner-box">


        { sessionType !== 'Monitoring' && !loading  &&
          <>
            <label>Choose a jpg image to upload</label>
            <form onSubmit={sendImage}>
              <input type="file" name="pdf" accept="image/*" onChange={inputHandelr}/>
              <button type="submit">Upload</button>
            </form>
            { activeRecord.parametersUrl !== 'notSet'
              &&  <img src={file} title="Production Video" className="vrap-image-preview"/>
            }
          </>
        }
        {
          sessionType !== 'Monitoring' && loading &&
          <Loader title = "Uploading image"/>
        }
        {
          sessionType === 'Monitoring' && !loading && activeRecord.parametersUrl !== 'notSet' &&
          <img src={file} title="Production Video" className="vrap-image-preview"/>
        }
        {
          sessionType === 'Monitoring' && loading &&
          <Loader title = "Dowloading image"/>
        }
        {
          !loading && activeRecord.parametersUrl === 'notSet' &&
          <div className="flex-center" style={{ height: '100%' }}>
            <p>Parameter image not set yet</p>
          </div>

        }
      </div>
      <div className={'vrap-session-backButton'}>
        <IconButton
          className = { currentUnit.motorOption && currentUnit.motorOption === 'left' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
          char={size3Video === 'vrap-video3-normal' ? '4' : '3'}
          text="shrink the box"
          onClick={ () => setSize3Video( size3Video === 'vrap-video3-normal' ? 'vrap-video3-enlarged' : 'vrap-video3-normal')}
        />
      </div>

    </>
  )
}

export default Session3Camera



