import React from 'react'
import Title from '../../UI/Title/Title'
import AccountForm from './AccountForm'


const NewAccount = () => {

  return(
    <div>
      <Title text= "Lisää uusi tili" icon="j"/>
      <AccountForm
        formType="newAccount"
      />
    </div>
  )
}

export default NewAccount
