import React from 'react'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'

import './videoControls.css'
import { useDispatch, useSelector } from 'react-redux'
import { spinUnit } from '../../../../VrapUnits/reducers/rdc-vrapUnits'



const VideoButtons = ({ currentUnit }) => {
  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()
  const units = useSelector(state => state.vrapUnits)
  const buttonEventHandler = (motorOption) => {
    // token, units, unit, spin
    dispatch(spinUnit(token, units, currentUnit, motorOption))
  }
  return(
    <div className = "vrap-video-control-button-container">
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === 'left' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="q" text="< Rotate"
        onClick={() => buttonEventHandler('left')}
      />
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === '--oscillation 20' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="2" text="Miniloop"
        onClick={() => buttonEventHandler('--oscillation 20')}
      />
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === '--pause' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="B" text="Pause"
        onClick={() => buttonEventHandler('--pause')}
      />
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === 'right' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="r" text="Rotate >"
        onClick={() => buttonEventHandler('right')}
      />
    </div>
  )
}

export default VideoButtons