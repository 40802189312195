import React from 'react'
// import React, { useState } from 'react'

// import { useSelector } from 'react-redux'



const ErrorSpotting = () => {

  return(
    <div className="vrap-component-container">
      <div>Error spotting is coming here</div>
    </div>
  )
}

export default ErrorSpotting



