import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateProfile } from '../../../../reducers/userReducer'
import Button from '../../../UI/Button/Button'
import { deleteUser } from '../../../../services/users'
import { logout } from '../../../../reducers/userReducer'
import './ProfileForm.css'
import Modal from '../../../UI/Modal/Modal'
import langs from '../../../../langs'
import UiCard from '../../../UI/UiCard/UiCard'
import dayjs from 'dayjs'
import HelpPop from '../../../UI/HelpPop/HelpPop'
import { setNotification } from '../../../UI/Notification/notificationReducer'

const ProfileForm = () => {

  const user = useSelector(state => state.user)
  const userName = user.username ? user.username : ''
  const company = user.company
  const lN = user.language // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation
  const token = useSelector(state => state.user.token)

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordAgain, setPasswordAgain] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [language, setLanguage] = useState(user.language)

  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const userCreated = user ? user.userCreated : ''
  const expiryDate = user.expiryDate

  useEffect(() => {
    if(user){
      setFirstname(user.firstname)
      setLastname(user.lastname)
      setEmail(user.email)
      setUsername(user.username)
    }
  }, [user])

  const submitForm = async (e) => {
    e.preventDefault()
    if(validateForm()){
      const newUser = {
        firstname: firstname,
        lastname: lastname,
        username: username,
        email: email,
        language: language,
        company: company,
        password: password && password
      }
      dispatch(updateProfile(token, newUser, user.id, true))
      dispatch(setNotification({ notification: trans.informationUpdated[lN], type: 'default' }))
    }
  }

  const validateForm = () => {

    let validated = true
    const err = []

    !firstname && err.push(trans.firstNameNeeded[lN])

    !lastname && err.push(trans.LastNameNeeded[lN])

    !username && err.push(trans.userNameNeeded[lN])
    username.length < 5 && err.push(trans.userNameLength[lN])

    !email && err.push(trans.emailNeeded[lN])

    if(password){
      password !== passwordAgain && err.push(trans.pwdNoMatch[lN])
      password.length < 12 && err.push(trans.pwdAtLeastWidth[lN])
    }

    if(err.length > 0){
      setErrors(err)
      validated = false
    } else {
      setErrors([])
    }
    return validated
  }

  // Modal buttons
  const onClickConfirmDelete = async () => {
    await deleteUser(token, user.id)
    dispatch(logout())
    history.push('/')
    // dispatch(closeModal())
    dispatch(setNotification(trans.UserAccountDeleted[lN]))
  }

  const onClickCancel = () => {
    setShowModal(false)
  }

  // Button on profile form. Opens up the modal.
  const clickRemoveAccount = (e) => {
    e.preventDefault()
    setShowModal(true)
  }

  return(
    <UiCard
      headerTitle={`Käyttäjä: ${userName}`}
      footerInfo={trans.accountIngress[lN]}
    >
      { showModal &&
        <Modal
          title={trans.removeUserAccount[lN] + ' ?'}
          message={trans.removeAccountLegend[lN]}
          onClickConfirm={onClickConfirmDelete}
          onClickCancel={onClickCancel}
        />
      }

      <form className="UI-card-body-container">
        <p>{trans.created[lN]} {dayjs(userCreated).format('DD.MM.YYYY')}</p>
        { expiryDate && <p>Käyttöoikeus loppuu: {dayjs(expiryDate).format('DD.MM.YYYY')}</p> }

        {/* First Name */}
        <div className="input-group">
          <label>{trans.firstName[lN]} *</label>
          <input
            type="text"
            id="firstname"
            data-testid="firstname"
            value={firstname}
            onChange={ (event) => setFirstname(event.target.value)}
          />
        </div>

        {/* Last Name */}
        <div className="input-group">
          <label>{trans.lastName[lN]} *</label>
          <input
            type="text"
            id="lastname"
            data-testid="lastname"
            value={lastname}
            onChange={ (event) => setLastname(event.target.value)}
          />
        </div>

        {/* Language */}
        <div className="input-group">
          <label>{trans.language[lN]} *</label>
          <select
            id= "language"
            value={language}
            onChange={ (event) => setLanguage(event.target.value)}
            defaultValue = {language}
          >
            <option value="1">{trans.finnish[lN]}</option>
            <option value="0">{trans.english[lN]}</option>
          </select>
        </div>

        {/* UserName */}
        <div className="input-group">
          <label>{trans.userName[lN]} *</label>
          <input
            type="text"
            id="username"
            data-testid="username"
            value={username}
            onChange={ (event) => setUsername(event.target.value.toLowerCase())}
          />
        </div>

        {/* Email */}
        <div className="input-group">
          <label>trans.email{lN} *</label>
          <input
            type="email"
            id="email"
            data-testid="email"
            value={email}
            onChange={ (event) => setEmail(event.target.value)}
          />
        </div>

        {/* New Password */}
        <div className="input-group">
          <label>
            {trans.pwd[lN]}
          </label>
          <HelpPop
            text="Syötä uusi salasana, jos haluat vaihtaa sen"
          />

          <input
            type="new password"
            id="password"
            data-testid="password"
            value={password}
            onChange={ (event) => setPassword(event.target.value)}
          />
        </div>

        <div className="input-group">
          <label>{trans.pwdAgain[lN]}</label>
          <input
            type="new password"
            id="password-again"
            data-testid="password-again"
            value={passwordAgain}
            onChange={ (event) => setPasswordAgain(event.target.value)}
          />
        </div>

        <ul>
          { errors && errors.map((e,i) => {
            return <li key={i + '-error'}>{ e }</li>
          })}
        </ul>

        <div className="profile-form-buttons">
          <Button
            id="updateUser"
            type="buttonGreen"
            onClick={submitForm}
            label={trans.update[lN]}
            icon="update"
          />

          <Button
            id="delete-user-button"
            icon="bin"
            type="buttonAlert"
            label={trans.removeUser[lN]}
            onClick={clickRemoveAccount}
          >
          </Button>
        </div>
      </form>
    </UiCard>
  )
}

export default ProfileForm