import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ButtonTxtIcon from '../../../../../components/UI/ButtonTxtIcon/ButtonTxtIcon'

import IconButton from '../../../../../components/UI/IconButton/IconButton'
import { setNotification } from '../../../../../components/UI/Notification/notificationReducer'
// import { removeTagCategory, updateTagCategories } from '../../Tags/reducers/tagCategoryReducer'
import HelpText from '../../../../../components/UI/HelpText/HelpText'
import { updateUnit } from '../reducers/rdc-vrapUnits'
// import './bodyedit.css'


const BodyEdit = ({ item, setBodyContainer, setSelectedID }) => {
  const dispatch =  useDispatch()
  const user = useSelector(state => state.user)
  const help = useSelector(state => state.help.show)
  const items = useSelector(state => state.vrapUnits)

  const handleDelete =  () => {
    dispatch(setNotification ({
      notification: 'Ookkonää ny iha varma?',
      type: 'modal',
      onClickConfirm: () => {
        // dispatch(removeTagCategory(user.token, item.id, items ))
        setBodyContainer(null)
        setSelectedID(null)
      },
      onClickBack: () => {
        setBodyContainer(null)
      }
    }))
  }

  // ------------- input with update handler -------------------------------
  const DataInput = ({ title, data, name }) => {

    const handlerUpdateClient = () => {
      // close input
      setOpenform(false)
      //  bracket notation, so only modified key is updated
      const object = { [name]: newData }

      dispatch(updateUnit(user.token, object, item.id, item, items ))
    }
    // -------- delete handle -------------------



    // states
    const [openForm, setOpenform] = useState(false)
    const [newData, setNewData]= useState(data)

    return(
      <>
        <div className="card-list even-odd">
          <div className="flex-container-info">{title}</div>
          { openForm === false ?
            <>
              <div className="flex-item-right hover-pointer" onClick={() => { setOpenform(true)}}>{data}</div>
              {name && help === true &&
            <div className="flex-item-right align-items-end">
              <IconButton
                onClick={() => { setOpenform(true)}}
                className="icon-button-secondary"
                size="30px"
                char="L"
                text="Muokkaa"
              />
            </div>}
            </>
            :
            <>
              <div className="flex-item-right">
                <input
                  className="passed"
                  placeholder={data}
                  value = {newData}
                  onChange={(e) => {setNewData(e.target.value)}}
                />
              </div>
              {name &&
            <>
              <div className="flex-item-right align-items-end">
                <IconButton
                  onClick={handlerUpdateClient}
                  className="icon-button-main"
                  size="30px"
                  char="u"
                  text="OK"
                />
              </div>
              <div className="flex-item-right align-items-end">
                <IconButton
                  size="30px"
                  onClick={() => { setOpenform(false)}}
                  className="icon-button-gray"
                  char="X"
                  text="Peru"
                />
              </div>
            </>
              }
            </>
          }
        </div>
      </>
    )
  }

  return(
    <>
      <h4 className="body-header">Edit Vrap Unit data</h4>
      <HelpText
        text='
          Help text here
        '
      />
      <DataInput title = "Name for Vrap Unit"   data = {item.unitName}    name = "unitName"/>
      <DataInput title = "Main video source" data = {item.mainVideoSource}    name = "mainVideoSource"/>
      <DataInput title = "Production video source" data = {item.productionVideoSource}    name = "productionVideoSource"/>
      <DataInput title = "Parameter video source" data = {item.parametersVideoSource}    name = "parametersVideoSource"/>
      <ButtonTxtIcon
        label = "Delete Unit"
        className = "buttonRed mp-ml-20"
        onClick = {handleDelete}
        icon = "M"
      />



    </>
  )
}

export default BodyEdit