import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../components/Private/Home/Home'
import UserAdmin from '../components/Private/UserAdmin/UserAdmin'
import UpdateUser from '../components/Private/UserAdmin/UpdateUser/UpdateUser'
import NewUser from '../components/Private/UserAdmin/NewUser/NewUser'
import InviteUser from '../components/Private/UserAdmin/InviteUser/InviteUser'
import UserProfile from '../components/Private/UserProfile/UserProfile'
import UserTrashcan from '../components/Private/UserAdmin/UserTrashcan/UserTrashcan'
import EditAccount from '../components/Private/Accounts/EditAccount'
import Footer from '../components/UI/Footer/Footer'
import Todo from '../Apps/ToDo/ToDo/ToDo'
import Msg from '../Apps/Messages/Messages/Msg'
import Thread from '../Apps/Messages/Messages/Thread/Thread'

// Account management
import Accounts from '../components/Private/Accounts/Accounts'
import NewAccount from '../components/Private/Accounts/NewAccount'
import { useSelector } from 'react-redux'
import NavBar from '../components/UI/NavBar/NavBar'
import Vraps from '../Apps/Vraps/Vraps'
import BgAnimation from '../Apps/Vraps/components/BgAnimations/BgAnimation'
import RightAnimation from '../Apps/Vraps/components/BgAnimations/RightAnimation'
import LeftAnimation from '../Apps/Vraps/components/BgAnimations/LeftAnimation'

const SuperAdminRoutes = ({ userToDisplay }) => {
  const theme = useSelector(state => state.theme)

  return(
    <div className={ 'routes-container' + theme } >
      <Todo/>
      <Switch>
        <Route exact path="/home">                      <NavBar />                                            <Home />                            <Footer/> </Route>
        <Route exact path="/">                          <NavBar />                                           <Home />                            <Footer/> </Route>
        <Route exact path="/profile">                   <NavBar />                                       <UserProfile />                     <Footer/> </Route>
        <Route  path="/accounts/accounts" >             <NavBar />        <div className="container">  <Accounts/>       </div>            <Footer/> </Route>
        <Route path="/accounts/newaccount">             <NavBar />        <div className="container">  <NewAccount/>     </div>            <Footer/> </Route>
        <Route exact path="/accounts/editaccount/:id">  <NavBar />        <EditAccount/>                                                   <Footer/> </Route>
        <Route exact path="/admin/user/:id">            <NavBar />        <UpdateUser user={userToDisplay} />                              <Footer/> </Route>
        <Route exact path="/admin/trash">               <NavBar />        <UserTrashcan/>                                                  <Footer/> </Route>
        <Route exact path="/admin/users">               <NavBar />        <UserAdmin/>                                                     <Footer/> </Route>
        <Route exact path="/admin/newuser">             <NavBar />        <NewUser/>                                                       <Footer/> </Route>
        <Route exact path="/admin/invite">              <NavBar />        <InviteUser/>                                                    <Footer/> </Route>
        <Route exact path="/vraps">                      <NavBar />        <Vraps />             </Route>
        <Route exact path="/msg">   <NavBar />   <Msg />                               <div className="container"> </div>  <Footer/>  </Route>
        <Route exact path="/msg/:id"> <NavBar /> <Thread />        <Footer/>  </Route>
        <Route exact path="/bgAnimation"> <BgAnimation/></Route>
        <Route exact path="/rightAnimation"> <RightAnimation/></Route>
        <Route exact path="/leftAnimation"> <LeftAnimation/></Route>

      </Switch>
    </div>
  )
}


export default SuperAdminRoutes