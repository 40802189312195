import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanies } from './reducers/companyReducer'
// import NavBar from './components/UI/NavBar/NavBar'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { initializeUsers } from './reducers/usersReducer'
import AccountInactive from './components/Private/Accounts/AccountInactive'
import Notification from './components/UI/Notification/Notification'
import SuperAdminRoutes from './routes/SuperAdminRoutes'
import StaffRoutes from './routes/StaffRoutes'
import AdminRoutes from './routes/AdminRoutes'
import LoginRoutes from './routes/LoginRoutes'
import getUsers from './utilities/getUsers'

import './mp.css'
import isUserLogged from './utilities/isUserLogged'
// import Poll from './utilities/Poll'

let userCompany = { active: false }
function App() {

  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.user)
  const companies = useSelector(state => state.companies)


  let users = useSelector(state => (!user || user.role !== 'admin' || user.role !== 'superadmin') ? null : state.users && state.users.allUsers)

  // Check if user is already logged in (in utilities)
  isUserLogged({ user, setCompanies, dispatch, useEffect, history })

  if(companies.length > 0){ userCompany = companies[0]}

  // get users (in utilities)
  getUsers({ user, initializeUsers, dispatch, useEffect })


  const showNotification = useSelector(state => state.notification && state.notification.show)

  const userMatch = useRouteMatch('/admin/user/:id')
  const userToDisplay = userMatch
    ? users ? users.find(u => u.id === userMatch.params.id) : null
    : null



  return (
    <div id="top">
      {/* Notifications */}
      {showNotification && <Notification/>}

      {/* -------- ROUTES (in routes folder)------------------*/}
      {/* ----- App routes are in Apps/AppROutes -------------*/}

      { /* -------- Login ---------*/
        !user &&
      <LoginRoutes/> }

      {/* SUPERUSER - HAS PRIVALIGIES TO MAKE NEW ACCOUNTS (company) -*/
        user && (  user.role === 'superadmin') &&
        <SuperAdminRoutes  userToDisplay = {userToDisplay}/>}

      { /* STAFF ROUTES ------------------------------------------------------------*/
        user && ( user.role === 'user' && userCompany.active === true) &&
        <StaffRoutes />}

      { /* ------ ADMIN ROUTES HAS PRIVALIGIES TO MANAGE ONE ACCOUNT (company)-------------------*/
        user && ( user.role === 'admin' && userCompany.active === true) &&
        <AdminRoutes userToDisplay={userToDisplay} />
      }

      {/* if account is inactive */}
      { (user  &&  userCompany.active === false ) &&
     <div className="routes-container">
       <Switch> <Route exact path="/*">   <AccountInactive/>  </Route> </Switch>
     </div>
      }
    </div>
  )
}

export default App