import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateProfile } from '../../../../reducers/userReducer'
import { setNotification } from '../../../UI/Notification/notificationReducer'
import Button from '../../../UI/Button/Button'
import dayjs from 'dayjs'
import './UserForm.css'
import { useHistory } from 'react-router-dom'
import { deleteUser } from '../../../../services/users'
import { initializeUsers } from '../../../../reducers/usersReducer'
import Modal from '../../../UI/Modal/Modal'
import langs from '../../../../langs'
import UiCard from '../../../UI/UiCard/UiCard'

const UserForm = ({ createUser, type, user }) => {

  const history = useHistory()
  const dispatch = useDispatch()

  // ---------- states from storage ----------------------------------------------------->

  const currentUser = useSelector(state => state.user)
  const lN = Number(currentUser.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang
  const companies = useSelector(state => state.companies)
  let userCompany
  if(user && companies) userCompany = companies.filter( company => company.id === user.company)[0].companyName

  // ---------- compnent states ---------------------------------------------------------->

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [username, setUsername] = useState('')
  const [role, setRole] = useState('user')
  const [password, setPassword] = useState('')
  const [passwordAgain, setPasswordAgain] = useState('')
  const [email, setEmail] = useState('')
  const [expiryDate, setExpiryDate] = useState((type === 'updateUser' && user.expiryDate) ? dayjs(user.expiryDate).format('YYYY-MM-DD') : Date())
  const [infiniteLicence, setInfiniteLicence] = useState((type === 'updateUser' && !user.expiryDate) ? true : false)
  const [active, setActive] = useState(type === 'updateUser' ? user.active : true)
  const [isStudent, setIsStudent] = useState(false)
  const [language, setLanguage] = useState(lN)
  const [company,setCompany] = useState(useSelector(state => state.user.company))
  const userRole = useSelector(state => state.user.role)
  const [errors, setErrors ] = useState([])
  const [showModal, setShowModal] = useState(false)  // Use modal On click delete


  // ---------- Company Options for listing companies in page return  -------------------->

  const CompanyOptions = () => {

    return(
      companies.map( company =>
        <option key={company.id} value ={company.id}>
          {company.companyName}
        </option>
      )
    )
  }

  // ---------- form intent: signUp /  newUser / updateUser ------------------------------->

  useEffect(() => {
    let mounted = true
    switch (type) {
    case 'signUp':
      if(mounted){
        setExpiryDate(user && user.expiryDate)
        user && !user.expiryDate && setInfiniteLicence(true)
        user && setEmail(user.email)
      }
      break

    case 'newUser':
      setExpiryDate(dayjs().add(1, 'year').format('YYYY-MM-DD'))
      setInfiniteLicence(false)
      break

    case 'updateUser':
      if(user && mounted){
        setFirstname(user.firstname)
        setLastname(user.lastname)
        setUsername(user.username)
        setRole(user.role)
        setEmail(user.email)
        setInfiniteLicence(user.expiryDate ? false : true)
        setExpiryDate(user.expiryDate ? dayjs(user.expiryDate).format('YYYY-MM-DD') : null)
        setIsStudent(user.isStudent)
        setCompany(user.company)
      }
      break

    default:
      break
    }
    return () => mounted = false
  }, [user, type])

  // ---------- submit form handler -------------------------------------------------------->

  const submitForm = async (e) => {
    e.preventDefault()
    let u // tmp var for User

    // if validated
    if(validateForm()){

      const newUser = {
        firstname: firstname,
        lastname: lastname,
        username: username,
        role: role,
        email: email,
        isStudent: isStudent,
        expiryDate: infiniteLicence ? null : expiryDate,
        password: password,
        active: active,
        language: language,
        company: company
      }

      // signUp user
      switch (type) {
      case 'signUp':
        await createUser(newUser)
        clearFields()
        break

      // new user
      case 'newUser':
        u = await createUser(currentUser.token, newUser)
        if(u){
          dispatch(setNotification({ notification: trans.newUserCreated[lN] }))
          clearFields()
          history.push('/admin/users')
        }

        // error <- user already exits
        else{
          dispatch(setNotification({
            notification: trans.userAlreadyExits[lN],
            type: 'alert'
          })
          )
        }
        break

      // update user
      case 'updateUser':{
        // Check if the user being update is myself
        const updateMyself = user.id === currentUser.id ? true : false
        dispatch(updateProfile(currentUser.token, newUser, user.id, updateMyself))
        dispatch(setNotification({ notification: trans.userUpdated[lN] }))
        // dispatch(initializeUsers(currentUser.token, company))
        break
      }

      default:
        break
      }
    }
  }
  // clear states
  const clearFields = () => {
    setFirstname('')
    setLastname('')
    setUsername('')
    setRole('user')
    setEmail('')
    setPassword('')
    setPasswordAgain('')
    setActive(true)
  }
  // ------- handle input ---------------------------------------------------------->

  const handleInput = (event) => {
    const id = event.target.id
    const val = event.target.value

    switch (id) {
    case 'firstname':         setFirstname(val);                      break
    case 'lastname':          setLastname(val);                       break
    case 'username':          setUsername(val.toLowerCase());         break
    case 'password':          setPassword(val);                       break
    case 'password-again':    setPasswordAgain(val);                  break
    case 'student':           setIsStudent(!isStudent);               break
    case 'infite-license':    setInfiniteLicence(!infiniteLicence);   break
    case 'active':            setActive(!active);                     break
    case 'expiry-date':       setExpiryDate(val);                     break
    case 'email':             setEmail(val);                          break
    case 'role':              setRole(val);                           break
    case 'language':          setLanguage(val);                       break
    case 'company':           setCompany(val);                        break
    default:                                                          break
    }
  }

  // ---------- validateForm ---------------------------------------------------------->

  const validateForm = () => {

    const err = []
    // pushing error messages if neserreary fields are not filled
    !firstname && err.push( trans.firstNameNeeded[lN] )
    // lastName
    !lastname && err.push(trans.LastNameNeeded[lN])
    // userName
    !username && err.push(trans.userNameNeeded[lN])
    // userName length
    username.length < 5 && err.push(trans.userNameLength[lN])
    // email, and validation (@)
    !email && err.push(trans.emailNeeded[lN])
    !email.includes('@') && err.push(trans.badEmail[lN])
    // password
    if(password){
      password.length < 3 && err.push(trans.pwdAtLeastWidth[lN])
      password !== passwordAgain && err.push(trans.pwdNoMatch[lN])
    }

    setErrors(err)
    return err.length > 0 ? false : true
  }

  // ---------- Delete User Modal actions ---------------------------------------------------------->

  // if conifemed user deletion
  const onClickConfirmDelete = async (e) => {
    e.preventDefault(e)
    await deleteUser(currentUser.token, user.id)
    dispatch(initializeUsers(currentUser.token, company))
    history.push('/admin/users')
    dispatch(setNotification({ notification: trans.userRemoved[lN] }))
    setShowModal(false)
  }

  // in modal id canceling selete user
  const onClickCancel = (e) => {
    e.preventDefault()
    setShowModal(false)
  }

  // modali if deleting user
  const openModal = (e) => {
    e.preventDefault()
    setShowModal(true)
  }

  // Errors, echoed in footer info
  const  Errors = () => {
    return(
      <ul>
        { errors.map(e => <li key = { Math.floor(Math.random() * 100000) }>{ e }</li>) }
      </ul>
    )
  }
  // --------------------------------------------------------------------------------------->
  // ------------------------- COMPONENT RETURN -------------------------------------------->
  // --------------------------------------------------------------------------------------->

  return(
    <UiCard
      headerTitle='Käyttäjätiedot'
      footerInfo = {<Errors/>}
    >
      <form className="UI-card-body-container">
        <p>Tähdellä merkityt tiedot ovat pakollisia</p>
        { showModal &&
        <Modal
          title= {trans.confirmUserRemove[lN]}
          message={ trans.operationRemoveUser[lN] + ` ${firstname} ${lastname} - ${username}.`}
          onClickCancel={onClickCancel}
          onClickConfirm={onClickConfirmDelete}
        />
        }
        {/* Company */}
        {/* Company is shown only for superuser, so other companies wont see each other */}
        { userRole === 'superadmin' &&
        <div className="input-group">
          <label htmlFor="company">Company* (Shown only in superadmin)</label>
          <select
            id="company"
            name="company"
            data-testid="company"
            type="text"
            onChange={handleInput}
          > <option value = {null}>{userCompany}</option>
            <CompanyOptions/>
          </select>
        </div>
        }
        {/* First Name */}
        <div className="input-group">
          <label htmlFor="firstname">{trans.firstName[lN]}*</label>
          <input
            id="firstname"
            name="firstname"
            data-testid="firstname"
            type="text"
            value={firstname}
            onChange={handleInput}
          />
        </div>

        {/* Last Name */}
        <div className="input-group">
          <label htmlFor="lastname">{trans.lastName[lN]}*</label>
          <input
            id="lastname"
            name="lastname"
            data-testid="lastname"
            type="text"
            value={lastname}
            onChange={handleInput}
          />
        </div>

        {/* User Name */}
        <div className="input-group">
          <label htmlFor="username">{trans.userName[lN]}*</label>
          <input
            id="username"
            name="username"
            data-testid="username"
            type="text"
            value={username}
            onChange={handleInput}
          />
        </div>

        {/* Laguage */}
        <div className="input-group">
          <label htmlFor="role">{trans.language[lN]}</label>
          <select
            id="language"
            name="language"
            data-testid="language"
            value={language}
            onChange={handleInput}
          >
            <option value="0">{trans.english[lN]}</option>
            <option value="1">{trans.finnish[lN]}</option>
          </select>
        </div>

        {/* Is student */}
        { (type === 'newUser' || type === 'updateUser')
        &&
        <>
          <div className="input-group mp-mt-30 mp-mb-30">
            <input
              id="student"
              name="student"
              data-testid="student"
              type="checkbox"
              value="student"
              onChange={handleInput}
            />
            <label htmlFor="student">{trans.student[lN]}</label>
          </div>

          {/* Role */}
          <div className="input-group">
            <label htmlFor="role">{trans.role[lN]}</label>
            <select
              id="role"
              name="role"
              data-testid="role"
              value={role}
              onChange={handleInput}
            >
              <option value="user">{trans.basicUser[lN]}</option>
              <option value="staff">{trans.staff[lN]}</option>
              <option value="admin">{trans.admin[lN]}</option>
              <option value="customer">Asiakas</option>
            </select>
          </div>
        </>
        }
        {/* E-mail */}
        <div className="input-group">
          <label htmlFor="email">{trans.email[lN]}*</label>
          <input
            id="email"
            name="email"
            data-testid="email"
            type="email"
            value={email}
            onChange={handleInput}
            readOnly={ type === 'signUp' ? true : false}
          />
        </div>

        {/* Password */}
        <div className="input-group">
          <label htmlFor="password">{trans.pwd[lN]}*</label>
          <input
            id="password"
            name="password"
            data-testid="password"
            type="new password"
            value={password}
            onChange={handleInput}
          />
        </div>

        {/* Password again */}
        <div className="input-group">
          <label htmlFor="password-again">{trans.pwdAgain[lN]}*</label>
          <input
            id="password-again"
            name="password-again"
            data-testid="password-again"
            className="fullWidth mp-mt-10"
            type="new password"
            value={passwordAgain}
            onChange={handleInput}
          />
        </div>

        {/* Expiry Date */}
        {(type === 'newUser' || type === 'updateUser')
      &&
        <div>
          <div className="input-group mp-mt-30 mp-mb-30">
            <input
              id="active"
              name="active"
              data-testid="active"
              type="checkbox"
              onChange={handleInput}
              value={active}
              defaultChecked={active}
            />
            <label htmlFor="active">{trans.activeUser[lN]}</label><br/>
          </div>
          <div className="input-group mp-mt-30 mp-mb-30">
            <input
              id="infite-license"
              name="infite-license"
              data-testid="infite-license"
              type="checkbox"
              onChange={handleInput}
              value={infiniteLicence}
              defaultChecked={infiniteLicence}
            />
            <label htmlFor="infinite-license">{trans.notExpiring[lN]}</label><br/>
          </div>
          { !infiniteLicence &&
          <>
            <label>{trans.chooseExpiryDay[lN]}</label><br/>
            <input
              id="expiry-date"
              name="expiry-date"
              data-testid="expiry-date"
              type="date"
              value={expiryDate}
              onChange={handleInput}
            />
          </>
          }
        </div>
        }


        {/* Buttons */}
        <div className="gap-20"/>
        <div id="user-form-buttons">
          <Button
            id="submit-user-form-button"
            type = "buttonGreen"
            onClick={submitForm}
            label = { type === 'signUp' ? trans.signUp[lN] : type === 'newUser' ? trans.createUser[lN] : trans.updateUser[lN] }
            icon = "update"
          />

          { (type === 'updateUser' && user && user.id !== currentUser.id) &&
          <Button
            id="delete-user-button"
            icon="bin"
            type="buttonAlert"
            label= {trans.userRemove[lN]}
            onClick={openModal}
          >
          </Button>
          }
        </div>
      </form>
    </UiCard>
  )
}

export default UserForm