// little (❓) popup button to show info text

import React, { useState } from 'react'
import './HelpPop.css'

const HelpPop = ({ text }) => {
  const[pop, setPop] = useState(false)
  const [style, setStyle] = useState('help-pop-hide help-pop')


  const PopContent = () => {

    return(
      pop === true &&
       <div className = {style}>
         <div className = {'helpPop-x-container'}>
           <div className = 'helpPop-x'>X</div>
         </div>
         {text}
       </div>
    )
  }

  const handToggler = () => {
    if(pop === false){
      setPop(true)
      setStyle('help-pop-show help-pop')
    } else {
      setStyle('help-pop-hide help-pop')
      setTimeout( () => setPop(false), 500)
    }
  }
  // const contStyle = () => {
  //   if(style === 'help-pop-show help-pop' + theme) return { position: 'fixed', width: '100vw', height: '100vh' }
  //   else return {}
  // }

  return(
    <div className = 'help-pop-container' style = { pop === true ? { transform: 'unset' } : {}}  onClick={handToggler}>
      <PopContent/>
    </div>
  )
}
export default HelpPop