import React from 'react'


const SwayingBands = () => {

  return(
    <div className="swaying-bands">
    </div>

  )
}
export default SwayingBands