import React from 'react'


const HorizontalRectangels = () => {

  return(
    <div className="horizontal-rectangles">
      <div className="horizontal-rectangles-div1"></div>
      <div className="horizontal-rectangles-div2"></div>
      <div className="horizontal-rectangles-div3"></div>
      <div className="horizontal-rectangles-div4"></div>
      <div className="horizontal-rectangles-div5"></div>
    </div>

  )
}
export default HorizontalRectangels