import axios from 'axios'
import { configurate } from '../../../services/configurate'
const baseUrl = '/private/api/vrapsession'

// ----------------------GET VRAPS --------------------------->

export const vrapsGet = async (token) => {
  try{
    const config = configurate(token)
    let response = await axios.get(baseUrl, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapsGet: ', err)
  }
}

// get One Session
export const vrapGetOne = async (token, id) => {

  try{
    const config = configurate(token)
    let response = await axios.get(baseUrl + '/' + id, config)
    return response

  } catch(err){
    console.log('Umhh... error at vrap/service/vrapGetOne: ', err)
  }
}

// POST CHAT
export const chatPost = async (token, body, sessionId) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl + '/chat/' + sessionId, body, config)

    console.log('response-rds: ', response)
    return response
  } catch(err){
    console.log('Umhh... error at custovrapsmers/service/vrapUpdate: ', err)
  }
}


// New Vrap | Session
export const vrapNew = async (token, body) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapNew: ', err)
  }
}


// add new parapm proposial
export const paramPropAdd = async (token, body, sessionId) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl + '/param/' + sessionId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at custovrapsmers/service/vrapUpdate: ', err)
  }
}

export const vrapUpdate = async (token, body, sessionId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/' + sessionId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at custovrapsmers/service/vrapUpdate: ', err)
  }
}

export const vrapDelete = async (token, sessionId) => {

  try{
    const config = configurate(token)
    let response = await axios.delete(baseUrl + '/' + sessionId, config)
    return response

  } catch(err){
    console.log('Umhh... error at custovrapsmers/service/vrapDelete: ', err)
  }
}